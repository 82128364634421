export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Actionable: [
      "BookAppointmentActionable",
      "CompleteHPIActionable",
      "CompleteLoggingActionable",
      "EducationalMaterialActionable",
      "FollowUpAppointmentActionable",
      "GenericActionable",
      "PayBillActionable",
      "ReferralActionable",
      "RxFillActionable",
    ],
    AdjustBillToSelfPayRateError: ["BillNotFound", "IllegalBillState", "Unauthorized"],
    ApplyCaseRateError: ["IllegalBillState", "InvalidCaseRate", "Unauthorized"],
    ApptStepMetadata: ["AdditionalVisitChargesStepMetadata", "PaymentStepMetadata"],
    BillHeartbeatResponse: ["BillLock", "BillLockNotFound"],
    BillLog: [
      "BillCreatedLog",
      "BillNote",
      "BillRefund",
      "BillStatusChange",
      "Installment",
      "PatientPayment",
      "PaymentReport",
      "ReverseWriteOffLog",
      "WriteOff",
    ],
    BillResponsibleParty: [
      "InsuranceResponsibleParty",
      "OrganizationResponsibleParty",
      "PatientResponsibleParty",
    ],
    BillTagMetadata: ["BillTagMetadataCommon", "BillTagMetadataSentToDebtCollection"],
    CarbyActionSuggestionItem: ["CarbyActionLabTestSuggestionItem", "CarbyActionRxSuggestionItem"],
    ChangeRenderingProviderOnBillError: [
      "BillNotFound",
      "InvalidRenderingProvider",
      "Unauthorized",
    ],
    ChangeSupervisingProviderOnAppointmentError: [
      "ApptNotFound",
      "InvalidSupervisingProvider",
      "Unauthorized",
    ],
    CheckInStep: [
      "CheckInCreditCardCollectionStep",
      "CheckInGenericStep",
      "CheckInPatientInformationStep",
      "CheckInPaymentStep",
      "CheckInResidualBalanceStep",
    ],
    Connection: [
      "ActionablesConnection",
      "AddressEntityConnection",
      "AppointmentsConnection",
      "ApptContractedPayerConnection",
      "AuditLogConnection",
      "AutomatedFollowUpConnection",
      "BillExecutionJobsConnection",
      "BillingQueueConnection",
      "BillsConnection",
      "BookableLocationImagesConnection",
      "BookableLocationsConnection",
      "CardScansAndMedicalImagesConnection",
      "CarePractitionerConnection",
      "CaseReportsConnection",
      "CasesConnection",
      "CovidHomeTestKitOrdersConnection",
      "CovidTestsConnection",
      "DiagnosisConnection",
      "DoctorLocationShiftConnection",
      "DocumentsConnection",
      "DrugFormConnection",
      "DrugRouteConnection",
      "EducationalMaterialConnection",
      "EmployeeConnection",
      "EntityOrderConnection",
      "InPersonFollowUpConnection",
      "InsuranceCardScansConnection",
      "InsuranceClaimsConnection",
      "InsuranceConnection",
      "LabRequestConnection",
      "LabResultsConnection",
      "LabTestPanelsConnection",
      "LocationStaffingLevelConnection",
      "MDEventConnection",
      "MedicalImageRequestConnection",
      "MedicationConnection",
      "MedicationGroupConnection",
      "MessageConnection",
      "NDCConnection",
      "NonProcessingRulesConnection",
      "OrganizationConnection",
      "OrganizationGroupsConnection",
      "OrganizationInvoiceConnection",
      "OrganizationInvoiceWithPeriodConnection",
      "OrganizationMembersConnection",
      "PatientIdentificationCardScansConnection",
      "PatientLogsConnection",
      "PaymentMethodConnection",
      "PermissionConnection",
      "PracticeAppointmentReasonConnection",
      "PracticesConnection",
      "PrescriptionConnection",
      "ProcessingRuleCheckpointConnection",
      "ProcessingRuleConnection",
      "ProviderAdjustmentConnection",
      "ReferralSuggestionConnection",
      "RegionalPractitionerConnection",
      "ReportedMedicationConnection",
      "RuleExecutionConnection",
      "ScratchpadConnection",
      "SearchBatchPaymentsConnection",
      "SearchRegionConnection",
      "SpecialtyConnection",
      "StaffingLevelConnection",
      "StaffingLevelShiftConnection",
      "StaffingRoleConnection",
      "TransactionsConnection",
      "UserGroupConnection",
      "UsersConnection",
      "VaccineConnection",
    ],
    CopyRuleBetweenPracticesError: [
      "InvalidRuleName",
      "RuleNotFound",
      "RuleToCopyAlreadyExists",
      "Unauthorized",
    ],
    DropBillFromDebtCollectionError: ["BillNotFound", "IllegalBillState", "Unauthorized"],
    FieldInsight: ["ChoiceInsights"],
    Insurance: ["HealthInsurance", "MedicalInsurance", "OtherInsurance"],
    LinkBCBSAccountError: ["Unauthorized"],
    LockBillError: ["AlreadyLockedByAnotherUser", "Unauthorized"],
    MarkBillAsFullyPaidError: ["BillNotFound", "IllegalBillState", "Unauthorized"],
    MutationResponse: [
      "AddApptStepSkipResponse",
      "AddAutomatedFollowUpsResponse",
      "AddBillsToQueueResponse",
      "AddCarePractitionerToPatientsHealthTeamResponse",
      "AddDiagnosisResponse",
      "AddDocumentResponse",
      "AddEducationalMaterialResponse",
      "AddEducationalMaterialsResponse",
      "AddEntityNoteResponse",
      "AddEntityOrdersResponse",
      "AddInPersonFollowUpsResponse",
      "AddLabRequestsResponse",
      "AddMedicalDocumentsResponse",
      "AddMedicalImageRequestsResponse",
      "AddMedicationAllergiesResponse",
      "AddMedicationGroupStatusResponse",
      "AddMedicationStatusesResponse",
      "AddPrescriptionsResponse",
      "AddReferralSuggestionsResponse",
      "AddRxToVendRxRelationResponse",
      "AddUserGroupPermissionsResponse",
      "AddUserPermissionsResponse",
      "AdjustBillToSelfPayRateResponse",
      "ApplyCaseRateResponse",
      "ApproveOrdersResponse",
      "ApproveRuleResponse",
      "AttachFloatingPatientPaymentToBillResponse",
      "AuthenticateLoginCodeResponse",
      "AuthenticatePasswordlessOrganizationAdminResponse",
      "AuthenticatePasswordlessResponse",
      "CancelBillInstallmentsResponse",
      "CancelCurrentReaderActionResponse",
      "ChangeRenderingProviderOnBillResponse",
      "ChangeSupervisingProviderOnAppointmentResponse",
      "ChargePatientCreditCardForBillResponse",
      "CompleteActionableResponse",
      "CompleteBillReviewResponse",
      "CopyRuleBetweenPracticesResponse",
      "CorrectClaimResponse",
      "CreateAuditEventResponse",
      "CreateBillInstallmentsResponse",
      "CreateBillNoteResponse",
      "CreateBillableResponse",
      "CreateBillingQueueResponse",
      "CreateCarePractitionerAndAddToPatientsHealthTeamResponse",
      "CreateCarePractitionerResponse",
      "CreateManualPaymentReportResponse",
      "CreatePatientLogResponse",
      "CreateProcessingRuleResponse",
      "CreateRuleResponse",
      "CreateSetupIntentResponse",
      "DeclareNoPatientHealthTeamMemberOfTypeExistsResponse",
      "DeleteApptStepSkipResponse",
      "DeleteAutomatedFollowUpResponse",
      "DeleteBillingQueueResponse",
      "DeleteBloodPressureCuffSizeResponse",
      "DeleteDiagnosisResponse",
      "DeleteDoctorLocationShiftResponse",
      "DeleteDrugAdministrationResponse",
      "DeleteEducationalMaterialResponse",
      "DeleteEntityNoteResponse",
      "DeleteEntityOrderResponse",
      "DeleteInPersonFollowUpResponse",
      "DeleteLabRequestResponse",
      "DeleteLabResultResponse",
      "DeleteMedicalDocumentResponse",
      "DeleteMedicalImageRequestResponse",
      "DeleteMedicalImageResponse",
      "DeleteMedicalImageResultResponse",
      "DeleteMedicationAllergyResponse",
      "DeletePatientLogResponse",
      "DeletePaymentReportResponse",
      "DeletePrescriptionResponse",
      "DeleteProviderAdjustmentResponse",
      "DeleteReferralSuggestionResponse",
      "DeleteRuleResponse",
      "DeleteTimeLogResponse",
      "DeleteUnbilledBillableResponse",
      "DismissActionableResponse",
      "DropBillFromDebtCollectionResponse",
      "FaxReferralPacketResponse",
      "FinalizeBillAndInvoicePatientResponse",
      "GenerateAndSaveReferralPacketResponse",
      "GenerateCCDAResponse",
      "GenerateDocumentUploadUrlResponse",
      "GenerateInvoicesResponse",
      "GenerateOccHealthInvoicesResponse",
      "GenerateOrganizationInvoiceResponse",
      "GenerateReferralPacketResponse",
      "GenerateSaveAndFaxReferralPacketResponse",
      "LinkBCBSAccountResponse",
      "LockBillResponse",
      "MarkBillAsFullyPaidResponse",
      "MarkInsuranceCoverageInactiveResponse",
      "NotifyPatientForBillResponse",
      "OrganizationAdminResponse",
      "OrganizationChangeMembersResponse",
      "OrganizationCreationResponse",
      "OrganizationMemberChangeResponse",
      "OverrideBillInstallmentsResponse",
      "PauseBillResponse",
      "PayBillAsGuestWithSavedMethodResponse",
      "PayOrganizationInvoiceWithACHResponse",
      "PayOrganizationInvoiceWithCheckResponse",
      "PayOrganizationInvoiceWithCreditCardResponse",
      "PaymentIntentCreateAndSendToReaderResponse",
      "PaymentIntentCreateForGuestResponse",
      "PaymentIntentCreateResponse",
      "PaymentMethodsSyncResponse",
      "PostCashPaymentForBillResponse",
      "PostCheckPaymentForBillResponse",
      "PublishCarePlanResponse",
      "RebillClaimResponse",
      "RecalculateSplitResponse",
      "RecordRefundForCashOrCheckPaymentResponse",
      "RecordRefundForSuccessfullyDisputedChargeResponse",
      "RefundCreditCardPatientPaymentResponse",
      "RemoveBillsFromQueueResponse",
      "RemovePatientFromOrganizationResponse",
      "RemovePaymentMethodResponse",
      "RemoveSecondaryTertiaryInsuranceResponse",
      "RemoveUserGroupPermissionsResponse",
      "RemoveUserPermissionsResponse",
      "ReorderBillInsurancesResponse",
      "ReorderProcessingRulesResponse",
      "ReverseBillWriteOffResponse",
      "ReverseCaseRateResponse",
      "ReviewDiagnosisAssociationResponse",
      "SendLoginCodeResponse",
      "SendOrganizationInvoiceGeneratedEmailResponse",
      "SendPasswordlessLoginResponse",
      "SendPasswordlessLoginToOrganizationAdminResponse",
      "SendPasswordlessLoginToPatientResponse",
      "SetBillInsuranceResponse",
      "SetBillResponsibilityToInsuranceResponse",
      "SetBillResponsibilityToOrganizationResponse",
      "SetBillResponsibilityToPatientResponse",
      "SetBloodPressureCuffSizeResponse",
      "SetDefaultPaymentMethodResponse",
      "SetProcessingRuleIsEnabledResponse",
      "SnoozeActionableResponse",
      "TagInsuranceUpdatedResponse",
      "UndeletePaymentReportResponse",
      "UnlockBillResponse",
      "UnpauseBillResponse",
      "UpdateAppointmentResponsiblePartyResponse",
      "UpdateAppointmentSpecialtyResponse",
      "UpdateAutomatedFollowUpResponse",
      "UpdateBillableDxCodesResponse",
      "UpdateBillableResponse",
      "UpdateBillingQueueResponse",
      "UpdateCarePlanInstructionsResponse",
      "UpdateCarePractitionerPatientRelationshipResponse",
      "UpdateEntityNoteAttributeValueResponse",
      "UpdateEntityNoteResponse",
      "UpdateEntityOrderResponse",
      "UpdateInPersonFollowUpResponse",
      "UpdateInsuranceCoverageAddressResponse",
      "UpdateInsuranceEffectiveAndTerminationDatesResponse",
      "UpdateLabRequestResponse",
      "UpdateMedicalImageRequestResponse",
      "UpdateMedicationAllergiesResponse",
      "UpdateMedicationGroupStatusResponse",
      "UpdatePatientInformationResponse",
      "UpdatePatientLogResponse",
      "UpdatePrescriptionResponse",
      "UpdateProcessingRuleResponse",
      "UpdateProviderNoteResponse",
      "UpdateReferralProviderIndexResponse",
      "UpdateReferralSuggestionResponse",
      "UpdateRuleResponse",
      "UpdateScratchpadResponse",
      "UpsertAdHocDoctorLocationShiftResponse",
      "UpsertDoctorLocationShiftResponse",
      "UpsertDoctorSettingResponse",
      "UpsertIFaxApiKeyResponse",
      "UpsertLocationStaffingLevelResponse",
      "UpsertProviderAdjustmentResponse",
      "UpsertStaffingLevelResponse",
      "UpsertStaffingLevelShiftResponse",
      "ViewedActionablesResponse",
      "VoidClaimResponse",
      "WriteOffBillResponse",
    ],
    Node: [
      "AddressEntity",
      "Appointment",
      "AppointmentReason",
      "ApptContractedPayer",
      "ApptReason",
      "ApptStepSkip",
      "AssignablePermission",
      "AuditActor",
      "AuditEntity",
      "AuditLog",
      "AutomatedFollowUp",
      "BatchPayment",
      "Bill",
      "BillExecutionJob",
      "BillLock",
      "BillNote",
      "BillRefund",
      "BillStatusChange",
      "BillTag",
      "Billable",
      "BillingQueue",
      "BillingTeam",
      "BillingUser",
      "BookAppointmentActionable",
      "BookableLocation",
      "BookableLocationAddress",
      "BookableLocationImage",
      "CarbyActionLabTestSuggestionItem",
      "CarbyActionRxSuggestionItem",
      "CarbyActionSuggestion",
      "CardScansAndMedicalImage",
      "CareGapGroup",
      "CareGapItem",
      "CarePlan",
      "CarePractitioner",
      "CarePractitionerImage",
      "CarePractitionerPatientRelationship",
      "Carrier",
      "Case",
      "CaseReport",
      "CheckInStepSkip",
      "CodeResult",
      "CompleteHPIActionable",
      "CompleteLoggingActionable",
      "Condition",
      "Consumer",
      "ContractedPayer",
      "ContractedPayerCoverageDetail",
      "CovidHomeTestKitOrder",
      "CovidTest",
      "Diagnosis",
      "Doctor",
      "DoctorLocationShift",
      "Document",
      "DrugAdministration",
      "DrugForm",
      "DrugName",
      "DrugPackaging",
      "DrugRoute",
      "DrugStrength",
      "EducationalMaterial",
      "EducationalMaterialActionable",
      "Employee",
      "EntityMedicalSchema",
      "EntityNote",
      "EntityOrder",
      "FilledPrescription",
      "FollowUpAppointmentActionable",
      "Form",
      "FrozenSchema",
      "GenericActionable",
      "HealthInsurance",
      "ICD10Code",
      "Image",
      "InPersonFollowUp",
      "Installment",
      "InsuranceCardScan",
      "InsuranceClaim",
      "LabRequest",
      "LabResult",
      "LabResultObservation",
      "LabTest",
      "LabTestPanel",
      "Location",
      "LocationStaffingLevel",
      "LoggingEntity",
      "MDEntity",
      "MDEvent",
      "Material",
      "MediaItem",
      "MedicalDocument",
      "MedicalImage",
      "MedicalImageRequest",
      "MedicalImageRequestResult",
      "MedicalInsurance",
      "MedicalSchema",
      "MedicalSchemaAttribute",
      "MedicalSchemaChoice",
      "MedicalSchemaChoiceGroup",
      "MedicalSchemaEntity",
      "Medication",
      "MedicationAllergy",
      "MedicationAllergyRecord",
      "MedicationGroup",
      "MedicationGroupStatus",
      "MedicationReaction",
      "MedicationStatus",
      "Message",
      "MessageThread",
      "MultiplePaymentRequest",
      "NDC",
      "NDCToDrugPackaging",
      "NdcToVendRxInventoryItems",
      "NearbyClinic",
      "NonProcessingRule",
      "OrderableLabTest",
      "Organization",
      "OrganizationAdmin",
      "OrganizationAdminUser",
      "OrganizationGroup",
      "OrganizationInvoice",
      "OrganizationInvoiceWithPeriod",
      "OrganizationMember",
      "OtherInsurance",
      "Patient",
      "PatientCareGapItem",
      "PatientCareGapItemRecord",
      "PatientIdentificationCardScan",
      "PatientLog",
      "PatientPayment",
      "PatientUser",
      "PayBillActionable",
      "Payment",
      "PaymentMethod",
      "PaymentReport",
      "PaymentReportItem",
      "PaymentReportItemAdjustment",
      "Permission",
      "Pharmacy",
      "Practice",
      "PracticeAppointmentReason",
      "PracticeAppointmentReasonSpecialty",
      "PractitionerContactInfo",
      "Prescription",
      "ProcessingRule",
      "ProcessingRuleCheckpoint",
      "ProductOperateUser",
      "ProviderAdjustment",
      "ProviderUser",
      "PublicImage",
      "ReferralActionable",
      "ReferralRequest",
      "ReferralRequestSendAttempt",
      "ReferralSuggestion",
      "RegionalPractitioner",
      "ReportedMedication",
      "Route",
      "RuleExecution",
      "RxFillActionable",
      "SchemaJson",
      "Scratchpad",
      "SearchRegion",
      "Specialty",
      "SpecimenContainer",
      "StaffingLevel",
      "StaffingLevelShift",
      "StaffingRole",
      "TreatmentPlan",
      "TreatmentPlanMacro",
      "UnauthenticatedBill",
      "UnauthenticatedFailedCharge",
      "UnauthenticatedInsurance",
      "UnauthenticatedPatient",
      "UnauthenticatedPayment",
      "UnauthenticatedRefund",
      "UnauthenticatedUser",
      "User",
      "UserGroup",
      "Vaccine",
      "VendRx",
      "VendRxInventoryItem",
      "Vitals",
      "Workflow",
      "WriteOff",
      "WriteOffReason",
    ],
    NotifyPatientForBillError: ["Unauthorized", "UnsupportedNotifyPatientForBillNotificationType"],
    PauseBillError: ["BillNotFound", "IllegalBillState", "InvalidBillStatusReason", "Unauthorized"],
    Practitioner: ["CarePractitioner"],
    ReorderBillInsurancesError: ["BillNotFound", "InvalidBillInsuranceOrdering", "Unauthorized"],
    ReverseBillWriteOffError: [
      "IllegalBillState",
      "StatusUpdateFailedForBill",
      "Unauthorized",
      "WriteOffNotFound",
    ],
    ReverseCaseRateError: ["IllegalBillState", "InvalidCaseRate", "Unauthorized"],
    RuleConditionV2: ["RuleFilterCondition", "RuleQueryCondition"],
    RuleV2: ["NonProcessingRule", "ProcessingRule"],
    Transaction: ["BillRefund", "PatientPayment", "PaymentReport", "WriteOff"],
    UnlockBillError: ["BillLockNotFound", "Unauthorized"],
    UnpauseBillError: ["BillNotFound", "IllegalBillState", "Unauthorized"],
    UpdateInsuranceCoverageAddressError: [
      "AddressNotBelongToPatient",
      "InsuranceCoverageOrAddressNotFound",
      "Unauthorized",
    ],
    UpdateInsuranceEffectiveAndTerminationDatesError: [
      "InsuranceCoverageNotFound",
      "InvalidEffectiveOrTerminationDate",
      "Unauthorized",
    ],
    UpdateProcessingRuleError: [
      "InvalidProcessingRuleCondition",
      "InvalidRuleName",
      "RuleNotFound",
      "Unauthorized",
    ],
    UserBoundary: [
      "AuthenticationError",
      "BillingUser",
      "OrganizationAdminUser",
      "PatientUser",
      "ProductOperateUser",
      "ProviderUser",
    ],
    VaccinationConsentBoundary: ["ConsentError", "CovidVaccination"],
    ValidateBillAgainstProcessingRuleConditionError: [
      "ProcessingRuleConditionValidationBillNotFound",
      "ProcessingRuleConditionValidationError",
      "ProcessingRuleConditionValidationUnexpectedError",
    ],
  },
};
export default result;
