import { gql, useQuery } from "@apollo/client";
import isNil from "lodash/isNil";
import { useRouter } from "next/router";
import { useCallback, useMemo, useRef, useState } from "react";
import { View } from "react-native";

import { setStoredPracticeId, useLocalPracticeId } from "src/auth/utils/practice";
import { throwIfUnauthenticated } from "src/auth/utils/throwIfUnauthenticated";
import { client } from "src/common/utils/graphql";
import { tailwind } from "src/foundations/styles";
import { Icon, Paragraph } from "src/foundations/ui";
import { Maybe } from "src/graphql/types/__generated__/type";
import Menu, { MenuItem } from "src/ui/components/Menu";
import PressableHighlight from "src/ui/components/PressableHighlight";

import {
  PracticeSelectorQuery,
  PracticeSelectorQueryVariables,
} from "./__generated__/PracticeSelector.type";

export const practiceSelectorQuery = gql`
  query PracticeSelector {
    me {
      ... on BillingUser {
        id
        myAccessibleDoctors {
          id
          practice {
            id
            name
          }
        }
      }
    }
  }
`;

function PracticeSelector(): JSX.Element {
  const selectedPracticeId = useLocalPracticeId();
  const practiceSelectorRef = useRef<View>(null);

  const [isOpen, setIsOpen] = useState(false);
  const { data, loading } = useQuery<PracticeSelectorQuery, PracticeSelectorQueryVariables>(
    practiceSelectorQuery
  );

  const me = throwIfUnauthenticated(data?.me);
  const userId = me?.id;
  const items = useMemo(() => {
    return (
      me?.myAccessibleDoctors?.map((practice) => ({
        label: practice?.practice?.name ?? "Unknown",
        value: practice?.practice?.id ?? null,
      })) ?? []
    ).sort((a, b) => (a.label ?? "").localeCompare(b.label ?? ""));
  }, [me?.myAccessibleDoctors]);

  const selectedPractice = items?.find((item) => item.value === selectedPracticeId);

  const setIsOpenTrue = useCallback(() => {
    setIsOpen(true);
  }, []);

  const setIsOpenFalse = useCallback(() => {
    setIsOpen(false);
  }, []);
  const router = useRouter();

  const onChange = useCallback(
    async (value: Maybe<string>) => {
      setIsOpen(false);
      if (isNil(value) || value === selectedPracticeId || isNil(userId)) {
        return;
      }
      await setStoredPracticeId(value, userId);
      await router.push(
        router.pathname.startsWith("/[practiceId]/teams")
          ? `/${value}`
          : router.pathname.startsWith("/[practiceId]/settings")
          ? `/${value}/settings`
          : `/${value}`
      );
      await client.clearStore();
    },
    [router, selectedPracticeId, userId]
  );

  const shouldNotRenderComponent = loading || (me?.myAccessibleDoctors?.length ?? 0) <= 1;

  return shouldNotRenderComponent ? (
    <></>
  ) : (
    <>
      <PressableHighlight
        accessibilityRole="button"
        ref={practiceSelectorRef}
        style={[tailwind("flex-row self-start items-center p-2 rounded-lg")]}
        onPress={setIsOpenTrue}
      >
        <View style={tailwind("m-2 flex-none")}>
          <Paragraph>{selectedPractice?.label}</Paragraph>
        </View>
        <View style={tailwind("flex-none")}>
          <Icon name="chevron-up-down" size={20} />
        </View>
      </PressableHighlight>

      <Menu
        nativeID="practiceSelectorMenu"
        anchor={practiceSelectorRef}
        isOpen={isOpen}
        onClose={setIsOpenFalse}
        placement="bottom left"
      >
        {items.map((item) => (
          <MenuItem key={item.value} onPress={() => onChange(item.value)}>
            <View style={[{ width: 240 }, tailwind("flex-row justify-between items-center p-2")]}>
              <Paragraph level={2}>{item.label ?? "Unknown"}</Paragraph>
              {item.value === selectedPracticeId && <Icon name="check" size={16} />}
            </View>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default PracticeSelector;
